<template>
    <div class="main-wrapper">
        <el-scrollbar class="main-wrapper-scrollbar">
            <div class="wrapper-list">
                <div class="top-title">
                    <img class="title-left" src="../../../../assets/images/student/line-left.png" alt="">
                    <span class="title">{{examInfo.op_name}}</span>
                    <img class="title-right" src="../../../../assets/images/student/line-right.png" alt="">
                    <!-- <el-button class="backBtn" type="primary" @click="goBack">返回</el-button> -->
                </div>
                <img src="../../../../assets/images/student/bottom-line.png" style="max-width: 100%; display: block" alt="">
                <div class="time-item">
                    <div class="time-item-list">
                        <div class="time-title-left">
                            <span class="comma" style="margin-left: 22px">,,,</span>
                            <div class="item">
                                <span class="score-title">本题分值：{{examInfo.score}}分</span>
                                <span style="margin-left: 50px">考核时间{{examInfo.assess_time}}分钟</span>
                            </div>
                            <span class="comma">,,,</span>
                        </div>
                        <div class="start-tex" @click="startBtn">{{isExamOrTrain ? '开始考试' : '开始训练'}}</div>
                    </div>
                </div>
                <!--S 任务目标-->
                <!-- <div class="mission-wrapper" v-if="examInfo.task_goal">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务目标</span>
                    </div>
                    <div class="task-item" v-for="(item, index) in examInfo.task_goal" :key="index">
                        <div class="task-bg">
                            <img src="../../../../assets/images/student/task-bg.png" alt="">
                            <p class="num-title"><em style="color: #FEDB2E">N0.</em>任务{{index + 1}}</p>
                        </div>
                        <div class="task-content">
                            <p class="text">{{item.target}}</p>
                        </div>
                    </div>
                </div> -->
                <!--E 任务目标-->
                <!--S 任务背景-->
                <div class="mission-wrapper" v-if="examInfo.task_background">
                    <div class="mission-title">
                        <!-- <span class="line"></span> -->
                        <!-- <span class="mission-title-text">任务背景</span> -->
                    </div>
                    <div class="task-item">
                        <div class="task-content">
                            <p class="text" v-html="examInfo.task_background"></p>
                        </div>
                    </div>
                </div>
                <!--E 任务背景-->
                <!-- <div class="mission-wrapper" v-if="examInfo.task_analysis">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务分析</span>
                    </div>
                    <div class="task-item">
                        <div class="task-content">
                            <p class="text" v-html="examInfo.task_analysis"></p>
                        </div>
                    </div>
                </div>

                <div class="mission-wrapper" v-if="examInfo.task_operation">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">任务操作</span>
                    </div>
                    <div class="task-item">
                        <div class="task-content">
                            <p class="text" v-html="examInfo.task_operation"></p>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="mission-wrapper" v-if="examInfo.scoring_criteria">
                    <div class="mission-title">
                        <span class="line"></span>
                        <span class="mission-title-text">评分标准</span>
                    </div>
                    <div class="task-item">
                        <div class="task-content">
                            <el-table :data="examInfo.scoring_criteria" style="width: 100%; flex: 1" size="medium" border
                                      :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#C4F2E4',fontSize: '16px',height:'60px'}"
                                      :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
                                <el-table-column prop="index" label="序号" align="center" width="80px"></el-table-column>
                                <el-table-column prop="content" label="考核内容" align="center"></el-table-column>
                                <el-table-column prop="require" label="考核要点" align="center"></el-table-column>
                                <el-table-column prop="score" label="配分" align="center"></el-table-column>
                                <el-table-column prop="standard" label="评分标准" align="center"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div> -->
            </div>
        </el-scrollbar>
    </div>
</template>
<script>
    import { stuOpGetExamInfo } from '@/utils/apis'

    export default {
        name: "Index",
        data() {
            return {
                op_id: Number(this.$route.query.op_id) || null,
                examInfo: {
                    id: null, // id实际就是op_id
                    op_name: '', //名称1
                    score: null, //分数1
                    assess_time: null, //时间1
                    task_goal: [], //任务目标1
                    task_background: '', //任务背景1
                    task_analysis: '', //任务分析
                    task_operation: '', //任务操作
                    scoring_criteria: [], //评分标准
                    file_download: '', //素材下载地址1
                    exam_module_id: null,
                    data_center_tpl_id: null,
                },
                isExamOrTrain: localStorage.getItem('examId'), // 有考试， 没有训练
            }
        },

        mounted() {
            this.getExamStuExamPage()
        },
        methods: {
            getExamStuExamPage() {
                let params = {
                    type: 'data_analysis_application'
                }
                if (this.op_id) {
                    params.op_id = this.op_id
                }
                stuOpGetExamInfo(params).then((res) => {
                    console.log(res,"kkkk");
                    this.examInfo = res.data
                }).catch((err) => {});
            },
            goBack(){
                this.$router.go(-1)
            },
            startBtn() {
                if (this.isExamOrTrain) { // 考试的
                    if (this.examInfo.exam_module_id === 44) { // 电子商务数据采集与处理
                        let routeDate = this.$router.resolve({
                            path: '/student/exam/ECommerceDataAnalysisAndApplication/collectionAdnProcess',
                            query: {
                                op_id: this.examInfo.id,
                                exam_module_id: this.examInfo.exam_module_id,
                                data_center_tpl_id: this.examInfo.data_center_tpl_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }
                    if (this.examInfo.exam_module_id === 45) { // 电子商务基础数据分析
                        let routeDate = this.$router.resolve({
                            path: '/student/exam/ECommerceDataAnalysisAndApplication/basicDataAnalysis',
                            query: {
                                op_id: this.examInfo.id,
                                exam_module_id: this.examInfo.exam_module_id,
                                data_center_tpl_id: this.examInfo.data_center_tpl_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }
                    if (this.examInfo.exam_module_id === 46) { // 电子商务数据报告制作
                        let routeDate = this.$router.resolve({
                            path: '/student/exam/ECommerceDataAnalysisAndApplication/reportProduct',
                            query: {
                                op_id: this.examInfo.id,
                                exam_module_id: this.examInfo.exam_module_id,
                                data_center_tpl_id: this.examInfo.data_center_tpl_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }
                    if (this.examInfo.exam_module_id === 50 || this.examInfo.exam_module_id === 97) { // 电子商务数据报报表制作
                        console.log("kkkk");
                        let routeDate = this.$router.resolve({
                            path: '/student/trainCenter/ECommerceDataAnalysisAndApplication/reportProductDesign',
                            query: {
                                op_id: this.examInfo.id,
                                exam_module_id: this.examInfo.exam_module_id,
                                data_center_tpl_id: this.examInfo.data_center_tpl_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }
                } else { // 训练的
                    if (this.examInfo.exam_module_id === 44) { // 电子商务数据采集与处理
                        let routeDate = this.$router.resolve({
                            path: '/student/trainCenter/ECommerceDataAnalysisAndApplication/collectionAdnProcess',
                            query: {
                                op_id: this.examInfo.id,
                                exam_module_id: this.examInfo.exam_module_id,
                                data_center_tpl_id: this.examInfo.data_center_tpl_id,
                                course_id: this.$route.query.course_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }
                    if (this.examInfo.exam_module_id === 45) { // 电子商务基础数据分析
                        let routeDate = this.$router.resolve({
                            path: '/student/trainCenter/ECommerceDataAnalysisAndApplication/basicDataAnalysis',
                            query: {
                                op_id: this.examInfo.id,
                                exam_module_id: this.examInfo.exam_module_id,
                                data_center_tpl_id: this.examInfo.data_center_tpl_id,
                                course_id: this.$route.query.course_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }
                    if (this.examInfo.exam_module_id === 46) { // 电子商务数据报告制作
                        let routeDate = this.$router.resolve({
                            path: '/student/trainCenter/ECommerceDataAnalysisAndApplication/reportProduct',
                            query: {
                                op_id: this.examInfo.id,
                                exam_module_id: this.examInfo.exam_module_id,
                                data_center_tpl_id: this.examInfo.data_center_tpl_id,
                                course_id: this.$route.query.course_id,
                            }
                        })
                        window.open(routeDate.href, '_blank')
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep p{
        background: none!important;
    }
    .main-wrapper {
        background: url("../../../../assets/images/student/student-sct-bg.png") no-repeat;
        background-size: 100% 100%;
        height: 100%;
        position: relative;
        .main-wrapper-scrollbar {
            height: 100%;
            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .wrapper-list {
                .top-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    font-size: 48px;
                    color: #35c773;
                    padding-top: 20px;
                    padding-bottom: 5px;
                    letter-spacing: 4px;
                    font-weight: bold;
                    position: relative;
                    .title {
                        min-height: 70px;
                        line-height: 1.2;
                    }
                    .title-left {
                        margin-right: 50px;
                    }
                    .title-right {
                        margin-left: 50px;
                    }
                    .backBtn{
                        position: absolute;
                        right: 40px;
                        top: 0;
                    }

                }
                .time-item {
                    /*position: absolute;*/
                    /*top: 180px;*/
                    margin-top: 80px;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .time-item-list {
                        display: flex;
                        height: 80px;
                        line-height: 80px;
                        background: #39B678;

                        .time-title-left {
                            flex: 1;
                            display: flex;
                            border: 6px solid #61a885;
                            background: #DDFDF2;

                            .comma {
                                color: #31A588;
                                font-size: 66px;
                                letter-spacing: 18px;
                                text-align: center;
                                line-height: 33px;
                                display: inline-block;
                            }

                            .item {
                                flex: 1;
                                font-size: 24px;
                                padding: 0 40px;
                                color: #31A588;
                                position: relative;

                                &:after {
                                    content: '';
                                    height: 40px;
                                    width: 3px;
                                    background: #31A588;
                                    position: absolute;
                                    top: 14px;
                                    right: 10px;
                                }

                                &:before {
                                    content: '';
                                    height: 40px;
                                    width: 3px;
                                    background: #31A588;
                                    position: absolute;
                                    top: 14px;
                                    left: 10px;
                                }
                            }
                        }

                        .start-tex {
                            width: 120px;
                            font-size: 24px;
                            color: #fff;
                            cursor: pointer;
                            text-align: center;
                        }
                    }

                }
            }
            .mission-wrapper {
                width: 100%;
                .mission-title {
                    width: 100%;
                    height: 80px;
                    text-align: center;
                    font-size: 48px;
                    font-style: italic;
                    font-weight: 600;
                    position: relative;
                    margin-top: 40px;

                    .line {
                        position: absolute;
                        top: 40px;
                        left: calc(50% - 150px);
                        width: 300px;
                        height: 20px;
                        background: #FEDB2E;
                    }

                    .mission-title-text {
                        position: inherit;
                        /*left: calc(50% - 100px);*/
                        text-align: center;
                    }
                }

                .task-item {
                    margin-top: 36px;
                    .task-bg {
                        color: #fff;
                        position: relative;
                        margin-left: 50px;

                        .num-title {
                            font-size: 34px;
                            position: absolute;
                            top: -26px;
                            left: 50px;

                            em {
                                font-style: normal;
                            }

                        }
                    }

                    .task-content {
                        background: #DDFDF2;
                        padding: 20px 20px 20px 50px;
                        margin: -20px 20px;
                        ::v-deep img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                        .text {
                            ::v-deep p span {
                                background-color: transparent !important;
                            }
                            ::v-deep table {
                                tr {
                                    height: 50px;
                                    line-height: 50px;
                                    td {
                                        border: 1px solid #D2D2D2;
                                        border-right: 2px solid #fff;
                                        text-align: center;
                                        font-size: 14px;
                                        color: #333;
                                        &:first-child {
                                            border-right: 2px solid #fff;
                                        }
                                        &:last-child {
                                            border-right:  1px solid #D2D2D2;
                                        }
                                    }
                                    &:nth-child(2n) {
                                        td {
                                            background: #F2F9FA;
                                        }
                                    }
                                    &:nth-child(2n-1) {
                                        td {
                                            background: #C4F2E4;
                                        }
                                    }
                                }
                            }
                        }
                        ::v-deep .el-table--medium td, ::v-deep .el-table--medium th {
                            border-right: 2px solid #fff;
                        }
                        ::v-deep .el-table tr {
                            &:nth-child(even) {
                                td {
                                    background:#C4F2E4;
                                    border-bottom: 2px solid #fff
                                }
                            }
                            &:nth-child(odd) {
                                td {
                                    background:#F2F9FA ;
                                    border-bottom: 2px solid #fff
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>